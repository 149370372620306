<template>
  <div class="toast-wrapper">
    <div class="toast">
      <h2>{{message}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    message: String
  },
}


</script>

<style scoped>
.toast-wrapper {
  position: fixed;
  width: 100%;
  top: 20px;
}
.toast {
  padding: 10px 20px;
  color: white;
  background: #d0010155;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
  max-width: 400px;
  margin: 0 auto;
  border: 2px solid white;
  backdrop-filter: blur(5px);
}

h1 {
  font-weight: bold;
}
</style>