<template>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner">
          <img src="../assets/img/cross.svg" class="closebutton" @click="close()"/>
          <!-- Modal Content -->
          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    modalActive: Boolean
  },
  methods: {
    close(){
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-animation-enter-active, .modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from, .modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0131477c;
  backdrop-filter: blur(15px);
  color: white;
}
.modal .modal-inner {
  position: relative;
  max-width: 640px;
  width: 80%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  /* background-color: #192c31ba;
  border: 1px solid white;
  border-radius: 10px; */
  padding: 16px 16px;
  backdrop-filter: blur(10px);
  border: 1px solid white;
  min-height: 400px;
  border-radius: 10px;
}
.modal .modal-inner i {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}
.modal .modal-inner i:hover {
  color: crimson;
}
.modal .modal-inner button {
  padding: 20px 30px;
  border: none;
  font-size: 16px;
  background-color: crimson;
  color: #fff;
  cursor: pointer;
}
.closebutton {
  cursor: pointer;
  width: 25px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
  z-index: 30;
}
</style>