<template>
  <Transition>
  <div class="logged-in-content" v-show="showGO3App">
  <div id="nav" :class="{ 'nav-color-one' : styles.styleOne, 'nav-color-two' : styles.styleTwo}">
    <div class="left-side">
      <img alt="logo" class="logo" src="../src/assets/img/amex-logo.svg">
      <img alt="logo" class="logo" src="../src/assets/img/plus.svg">
      <img alt="logo" class="logo" src="../src/assets/img/arrow.svg">
      <img alt="logo" class="logo" style="margin-left: 25px" src="../src/assets/img/user-bis.svg">
      <img alt="logo" class="logo" src="../src/assets/img/wrapup.svg">
    </div>
    <div class="right-side">
      <input class="text-fields-app" :class="{ 'text-fields-app-style-one' : styles.styleOne, 'text-fields-app-style-two' : styles.styleTwo}" type="text" placeholder="Type a PCN Number" ref="account" v-model="account">
      <div class="user-pack">
        <img alt="logo" class="logo-small" src="../src/assets/img/user.svg">
        <router-link to="/">Geoffrey L Perin</router-link>
      </div>
      <a style="cursor: pointer" @click="logoff" >Log off</a>
      <a style="color: #8dc3e0; cursor: pointer" @click="showModal" >78</a>
    </div>
  </div>
  <div class="content">
  <div class="side-menu" :class="{ 'background-content-one' : styles.styleOne, 'background-content-two' : styles.styleTwo}">

    <div class="hamburger-menu">
      <div class="bar-top" :class="{ 'bar-top-style-one' : styles.styleOne, 'bar-top-style-two' : styles.styleTwo}"></div>
      <div class="bar-middle" :class="{ 'bar-middle-style-one' : styles.styleOne, 'bar-middle-style-two' : styles.styleTwo}"></div>
      <div class="bar-bottom" :class="{ 'bar-bottom-style-one' : styles.styleOne, 'bar-bottom-style-two' : styles.styleTwo}"></div>
    </div>

    <div class="links-list">
      <a href="#">Dashboard</a>
      <a href="#">Advanced Search</a>
      <a href="#">My Assistant</a>
      <a href="#">My Worklist</a>
      <a href="#">Report Browser (All Reports)</a>
      <a href="#">Bulk Process for PIV</a>
      <a href="#">Global PIV Reporting</a>
      <a href="#">Comparison Report</a>
      <a href="#">Comparison Search Criteria</a>
      <a href="#">GO2 L2 Dash Board</a>
      <a href="#">Data Instance History</a>
      <h4 style="font-weight: bold; font-size: 14px;">Recent</h4>
      <div class="list-of-last-pcn">
        <router-link to="/app">20222396900556022</router-link>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
        <a href="#">20222396900556022</a>
      </div>
    </div>

  </div>
  <div class="main-content" :class="{ 'background-content-one' : styles.styleOne, 'background-content-two' : styles.styleTwo}">
    <router-view/>
  </div>
  </div>
  </div>
  </Transition>
  <Transition>
    <div class="login-content" v-show="showLogin">
      <div class="login-struct">
        <img alt="logo-amex" class="logo-amex" src="./assets/img/amex-logo.svg">
        <div class="app-title">
<!--          <div class="app-logo">-->
<!--            <img alt="logo" class="logo" src="../assets/img/logo.png">-->
<!--          </div>-->
          <div class="title">
            <h2>GNA</h2>
            <h3>GO3 Specimen</h3>
          </div>
        </div>
        <div class="amex-inputs">
          <input class="text-fields" type="text" placeholder="Account" ref="account" v-model="account">
          <input class="text-fields" type="text" placeholder="Email" ref="email" v-model="email">
          <input class="text-fields" type="password" placeholder="Password" ref="password" v-model="password" @keydown="tryToLogin">
          <div class="button-layout">
            <AdaptableButton text="Login" color="#0049ff" central=false @click="login"/>
          </div>
        </div>
      </div>
      <transition name="toast">
        <Toast v-if="showToast" :message="message"/>
      </transition>
    </div>
  </Transition>
  <Transition>
    <div class="splash" v-show="showSplash">
      <div class="welcome">
        <img alt="Vue logo" class="logo-splash" src="./assets/img/amex-logo.svg">
        <h2>Global New Account</h2>
      </div>
      <h4 class="note-connection">Checking your connection...</h4>
    </div>
  </Transition>

  <Modal :modalActive="modalActive" @close="closeModal">

    <div class="modal-content">

      <h2 class="modal-title">{{modalTitle}}</h2>

      <div class="modal-body">

        <div class="button-layout-main">
          <AdaptableButton text="Original Style" color="#001245" central=false @click="changeStyleInSettings('original')"/>
        </div>

        <div class="button-layout-main">
          <AdaptableButton text="Amex Style" color="#001245" central=false @click="changeStyleInSettings('amex')"/>
        </div>

      </div>

    </div>

  </Modal>

</template>

<script>
import {getCurrentInstance, onBeforeMount} from 'vue';
// import { useRouter, useRoute } from 'vue-router';
import { firebaseAuth } from '@/main';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import Toast from './components/Toast.vue';
import AdaptableButton from './components/AdaptableButton.vue';
import Modal from './components/Modal.vue';
import { applySettings } from './settings/settings';

export default {
  components: {
    AdaptableButton,
    Toast,
    Modal
  },
  data(){
    return{
      showGO3App: false,
      showLogin:  false,
      showToast: false,
      showSplash: true,
      account: "",
      email: "",
      password: "",
      message: "",
      modalActive: false,
      modalTitle: "Change Style",
      // styles: {
      //   styleOne: true,
      //   styleTwo: false,
      //   styleThree: false
      // }
    }
  },
  setup(){

    const { styles, changeStyle } = applySettings();

    const instance = getCurrentInstance();

    function changeStyleInSettings(newStyle){
      changeStyle(newStyle)
      instance.data.modalActive = false
    }

    // const router = useRouter();
    // const route = useRoute();


    onBeforeMount(() => {

      firebaseAuth.onAuthStateChanged((user) => {

        if(!user){
          setTimeout(function() {
            instance.data.showGO3App = false;
            instance.data.showSplash = false;
            instance.data.showLogin = true;
          }, 2000);
        } else {
          setTimeout(function() {
            instance.data.showLogin = false;
            instance.data.showSplash = false;
            instance.data.showGO3App = true;
            }, 2000);
        }

        // if(!user){
        //   setTimeout(function() { router.replace('/login'); }, 2000);
        // } else if (route.path === '/login' || route.path === '/'){
        //   setTimeout(function() { router.replace('/home'); }, 2000);
        // }


      }, );

    });

    return { // make it available in <template>
      styles, changeStyleInSettings
    }



  },
  methods: {
    login(){

      const that = this;

      if(this.account === "Amex1850") {

        signInWithEmailAndPassword(firebaseAuth, this.email, this.password)
            .then(data => console.log(data))
            .catch(err => {
              console.log(err.message)
              that.triggerToast("Wrong credentials")
            });

      } else {
        this.triggerToast("Wrong account number")
      }
    },
    tryToLogin(){

      if(event.keyCode === 13){
        this.login()
      }

    },
    logoff(){
      signOut(firebaseAuth);
    },
    triggerToast(message){

      this.showToast = true;

      this.message = message

      setTimeout(() => this.showToast = false, 4000)

    },
    showModal(){

      if(!this.modalActive){
        this.modalActive = true;
      }

    },
    closeModal(){

      if(this.modalActive){
        this.modalActive = false;
      }

    },
  }

}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #00175a;
  overflow-y: hidden;
  position: relative;
}

html{
  overflow-y: hidden;
  background-color: #efefef;
}

body{
  margin: 0;
  overflow-y: hidden;
}

#nav {
  padding: 10px 25px;
  height: 25px;
  color: white;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 20;
  width: 97%;
}

.nav-color-one{
  background-color: #2d597f;
}

.nav-color-two{
  background-color: #001245;
}

.logo{
  width: 25px;
}

#nav a {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.text-fields{
  padding: 5px;
  width: 200px;
  height: 13px;
}

.left-side{
  display: flex;
  gap: 25px;
}

.right-side{
  display: flex;
  align-items: end;
  gap: 25px;
}

.logo{
  cursor: pointer;
}

.logo-small{
  width: 15px;
  cursor: pointer;
}

.user-pack{
  display: flex;
  gap: 3px;
  align-items: baseline;
}

.side-menu{
  position: relative;
  height: 100vh;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 48px;
  overflow-y: scroll;
}

.background-content-one{
  background-color: #efefef;
  color: #020203;
}

.background-content-one a, .background-content-one h4{
  color: rgb(7, 123, 170);
}

.background-content-two {
  background-color: #00175a;
  color: white;
}

.background-content-two a, .background-content-two h4{
  color: white;
}

.links-list{
  text-align: left;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.links-list a {
  font-size: 14px;
}

.list-of-last-pcn{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.links-list h4{
  margin: 0;
}

a{
  text-decoration: none;
}

.hamburger-menu {
  position: absolute;
  right: 0;
  top: 5px;
  display: flex;
  flex-flow: column wrap;
  /*justify-content: space-between;*/
  height: 2.5rem;
  width: 25px;
  cursor: pointer;
}
.bar-top,
.bar-middle,
.bar-bottom {
  height: 5px;
  margin: 1px 0;
  transform-origin: left;
  transition: all 0.5s;
}

.bar-top-style-one,
.bar-middle-style-one,
.bar-bottom-style-one{
  background: #077baa;
}

.bar-top-style-two,
.bar-middle-style-two,
.bar-bottom-style-two{
  background: #ffffff;
}

.content{
  position: relative;
  overflow-y: hidden;
}

.main-content{
  position: absolute;
  top: 0;
  left: 240px;
  padding: 0 20px;
  right: 0;
  text-align: left;
  height: -webkit-fill-available;
  padding-top: 48px;
  overflow-y: scroll;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.logo-amex{
  width: 100px;
}

.logo{
  max-width: 50px;
}

.login-content{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-struct{

  max-width: 800px;
  width: 80%;
  text-align: center;
  /*background-color: #0061b6;*/
  color: white;
  background-color: #02182b;
  border-radius: 5px;
  min-height: 200px;
  padding: 20px 20px 30px;
  box-shadow: 0 3px 30px rgba(130, 107, 107, 0.3)

}

.amex-inputs{
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
}

.text-fields{
  background-color: #ffffff;
  border: none;
  outline: none;
  color: #15243c;
  /*border-radius: 0px;*/
  font-family: inherit;
  font-weight: bold;
  font-size: 14px;
  padding: 6px 10px;
}

.text-fields-app{
  outline: none;
  font-family: inherit;
  font-weight: bold;
  font-size: 14px;
  padding: 6px 10px;
}

.text-fields-app-style-one{
  background-color: #ffffff;
  border: none;
  color: #15243c;
}

.text-fields-app-style-two{
  background-color: transparent;
  border: 1px solid white;
  color: #ffffff;
}

.title{
  padding-top: 10px;
  text-align: center;
}

.app-title{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  gap: 20px;
}

.logo{
  max-width: 150px;
}

.logo-splash{
  width: 80px;
  max-width: 150px;
}

.splash{
  height: 100vh;
  position: relative;
  color: white;
}

.welcome{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.note-connection{
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 50px;
  display: inline;
  width: fit-content;
  transform: translate(-50%, 0);
}

.button-layout-main{
  margin: 20px;
}

/*.hamburger-menu:hover .bar-top {*/
/*  transform: rotate(45deg);*/
/*}*/
/*.hamburger-menu:hover .bar-middle {*/
/*  transform:translateX(1rem);*/
/*  opacity: 0;*/
/*}*/
/*.hamburger-menu:hover .bar-bottom {*/
/*  transform: rotate(-45deg);*/
/*}*/

/*#nav a.router-link-exact-active {*/
/*  color: #42b983;*/
/*}*/

</style>
