import { ref } from 'vue'

const styles = ref({
        styleOne: true,
        styleTwo: false,
        styleThree: false
    });

export const applySettings = function() {

    // other functions, for example to mutate message ref

    function changeStyle(styleName: String){
        if(styleName === "original"){
            styles.value.styleTwo = false
            styles.value.styleOne = true
            // this.styles.styleTwo = false
            // this.styles.styleOne = true
        } else if(styleName === "amex"){
            styles.value.styleOne = false
            styles.value.styleTwo = true
            // this.styles.styleOne = false
            // this.styles.styleTwo = true
        }
    }

    return {
        styles,
        changeStyle
    }
}