<template>
  <button :style="{background: color, color: textColor}" class="homebuttons">{{text}}</button>
</template>

<script>
export default {
  name: 'Button',
  data(){
    return{

    }
  },
  props: {
    text: String,
    color: String,
    textColor: String
  }
}
</script>

<style scoped>

.homebuttons{
  /* background-color: white; */
  border: none;
  width: -webkit-fill-available;
  border-radius: 5px;
  padding: 10px;
  min-width: -moz-available; /* WebKit-based browsers will ignore this. */
  min-width: fill-available;
  cursor: pointer;
  height: 30px;
  font-weight: bold;
  background: #001245;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eeeeee;
  font-size: 15px;
  width: 220px;
}


</style>