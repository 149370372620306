
import {applySettings} from "@/settings/settings";


export default {
  setup(){

    const { styles } = applySettings();

    return { // make it available in <template>
      styles
    }

  }
}

